import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vue3GoogleLogin from "vue3-google-login";
import Toast, { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
import "@vuepic/vue-datepicker/dist/main.css";

// Access the environment variables
const baseUrl = process.env.DJANGO_API_URL;
const googleApiKey = process.env.VUE_GOOGLE_CLIENT_ID;

axios.defaults.baseURL = baseUrl;

// Create a toast instance
const toast = useToast({
  maxToasts: 1,
});

console.log = function () {};
// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check for a 401 status code
    if (error.response.status === 401) {
      // console.log(error.response);
      // Remove token from state and local storage
      localStorage.removeItem("access_token");
      localStorage.removeItem("expiry");
      // Redirect the user to the login page
      router.push("/login");
    }
    // Check for a 404 status code
    if (error.response.status === 404) {
      // Redirect the user to the login page
      router.push("/notfound");
    }

    if (error.response.status === 500) {
      console.error("errors ", error);
      toast.error(error?.response?.statusText, {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    }
    // Reject the promise with the error
    return Promise.reject(error);
  }
);

const app = createApp(App).use(store).use(router, axios);
const gAuthOptions = {
  clientId: googleApiKey,
  scope: "profile email",
};

app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  newestOnTop: true,
});

app.use(vue3GoogleLogin, gAuthOptions);
app.mount("#app");
